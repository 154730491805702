<template>
  <CommerciauxListeMainVue/>

</template>

<script>
import CommerciauxListeMainVue from '@/views/afridia/commerciaux/liste/CommerciauxListeMainVue.vue'

export default {
  name: 'MainVue',
  components: { CommerciauxListeMainVue },
  data() {
    return {
      commerciaux: [
      ],
    }
  },
  created() {
  },
  methods: {
  },
}
</script>

<style scoped>

</style>
