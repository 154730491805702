<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <b-card>
            <!-- input search -->
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Recherche
                  </label>
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Recherche"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
              <!-- primary -->
              <b-dropdown
                id="dropdown-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="Exporter"
                variant="primary"
                class="ml-2 btn_export"
              >
                <b-dropdown-item @click="exportCSV">
                  CSV
                </b-dropdown-item>
                <b-dropdown-item @click="exportExcel">
                  MS-Excel
                </b-dropdown-item>
              </b-dropdown>

              <!-- button -->
              <b-button
                id="toggle-btn"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.commercial-modal-prevent-closing
                variant="outline-primary"
                class="btn_export ml-2"
                @click="resetModal()"
              >
                <feather-icon icon="PlusCircleIcon" />
                Ajouter un commercial
              </b-button>

              <!-- modal -->
              <b-modal
                id="commercial-modal-prevent-closing"
                ref="commercial-modal"
                title="Nouveau Commercial"
                ok-title="Enregistrer"
                cancel-variant="outline-secondary"
                cancel-title="Annuler"
                size="lg"
                @hidden="resetModal"
                @ok="handleOk"
              >
                <form
                  ref="form"
                  @submit.stop.prevent="handleSubmit"
                >
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        :state="commercial.prenomState"
                        label="Prénom"
                        label-for="prenom-input"
                        invalid-feedback="Le prénom est requis"
                      >

                        <b-form-input
                          id="prenom-input"
                          v-model="commercial.prenom"
                          :state="commercial.prenomState"
                          required
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :state="commercial.nomState"
                        label="Nom"
                        label-for="nom-input"
                        invalid-feedback="Le nom est requis"
                      >

                        <b-form-input
                          id="nom-input"
                          v-model="commercial.nom"
                          :state="commercial.nomState"
                          required
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :state="commercial.telephoneState"
                        label="Téléphone"
                        label-for="phone-input"
                        invalid-feedback="Le téléphone est requis"
                      >

                        <b-form-input
                          id="phone-input"
                          v-model="commercial.telephone"
                          :state="commercial.telephoneState"
                          required
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Email"
                        label-for="email-input"
                        invalid-feedback="L'email est requis"
                      >

                        <b-form-input
                          id="email-input"
                          v-model="commercial.email"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="!commercial.uuid"
                      md="6"
                    >

                      <b-form-group
                        :state="commercial.loginState"
                        label="Login de connexion"
                        label-for="login-input"
                        invalid-feedback="Le login est requis"
                      >

                        <b-form-input
                          id="login-input"
                          v-model="commercial.login"
                          :state="commercial.loginState"
                          required
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="!commercial.uuid"
                      md="6"
                    >
                      <b-form-group
                        :state="commercial.mot_de_passeState"
                        label=" Mot de passe"
                        label-for="mot-de-passe-input"
                        invalid-feedback="Le mot de passe est requis"
                      >

                        <b-form-input
                          id="mot-de-passe-input"
                          v-model="commercial.mot_de_passe"
                          :state="commercial.mot_de_passeState"
                          type="password"
                          required
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :state="commercial.responsableState"
                        label="Responsable"
                        label-for="type-input"
                        invalid-feedback="Le responsable est requis"
                      >

                        <v-select
                          v-model="commercial.responsable"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="full_name"
                          :options="responsables"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :state="commercial.canalState"
                        label="Canal de distribution"
                        label-for="canal-input"
                        invalid-feedback="Le canal est requis"
                      >

                        <v-select
                          id="canal-input"
                          v-model="commercial.canal"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="nom"
                          :options="canaux"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :state="commercial.zoneState"
                        label="Zone de distribution"
                        label-for="zone-input"
                        invalid-feedback="La zone est requise"
                      >

                        <v-select
                          id="zone-input"
                          v-model="commercial.zone"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="nom"
                          :options="zones"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </form>
              </b-modal>
            </div>
            <vue-good-table
              id="list"
              :key="componentKey"
              ref="myTable"
              :columns="columns"
              :rows="rows"
              :rtl="direction"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >

                <!-- Column: Status -->
                <span v-if="props.column.field === 'nbr_tournes'">

                  {{ props.row.nbr_tournes_ok }} /

                </span>
                <span v-if="props.column.field === 'status'">
                  <b-badge :variant="statusVariant(props.row.status)">
                    {{ props.row.status }}
                  </b-badge>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <!--                  <feather-icon-->
                  <!--                    icon="Edit2Icon"-->
                  <!--                    class="mr-50"-->
                  <!--                  />-->
                  <!--                  <span>Edit</span>-->
                  <b-button-group>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      :to="{ name: 'commerciaux-details', params: { id: props.row.uuid } }"
                    >
                      Voir
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      @click="handleUpdate(props.row)"
                    >
                      Modifier
                    </b-button>
                  </b-button-group>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap">
                      Affichage de 1 à
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3','5','10']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap "> sur {{ props.total }} élements </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BBadge, BButton, BButtonGroup,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import VueGoodTable from 'vue-good-table/src/components/Table.vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import htmlToPaper from 'vue-html-to-paper'
import vSelect from 'vue-select'
import { API_URL } from '@/helpers/global-scops'
import showToast from '@/helpers/methodes'

export default {
  name: 'CommerciauxListeMainVue',
  components: {
    BButtonGroup,
    BButton,
    BBadge,
    VueGoodTable,
    BCard,
    BCol,
    BRow,
    BDropdown,
    BFormInput,
    BDropdownItem,
    BFormGroup,
    BFormSelect,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
    htmlToPaper,
  },
  props: {
    responsableId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      userData: {},
      componentKey: 0,
      responsables: [],
      zones: [],
      canaux: [
      ],
      commercial: {
        nom: '',
        prenom: '',
        telephone: '',
        email: '',
        canal: '',
        responsable: '',
        zone: '',
        login: '',
        mot_de_passe: '',
        nomState: null,
        prenomState: null,
        responsableState: null,
        zoneState: null,
        telephoneState: null,
        canalState: null,
        emailState: null,
        loginState: null,
        mot_de_passeState: null,
      },
      pageLength: 10,
      searchTerm: '',
      columns: [
        {
          label: 'Prénom',
          field: 'first_name',
          filterable: true,
        },
        {
          label: 'Nom',
          field: 'last_name',
          filterable: true,
        },
        {
          label: 'Telephone',
          field: 'telephone',
          filterable: true,
        },
        {
          label: 'Responsable',
          field: 'responsable_r.full_name',
          filterable: true,
        },
        {
          label: 'Canal',
          field: 'canal',
          filterable: true,
        },
        {
          label: 'Nombre de distributeurs',
          field: 'nbr_distributeur',
          type: 'number',
          filterable: true,
        },
        {
          label: 'Nombre de PDV',
          field: 'nbr_pdv',
          type: 'number',
          filterable: true,
        },
        {
          label: 'Nombre de visites PDV réalisé',
          field: 'nbr_visites_pdv',
          type: 'number',
          filterable: true,
        },
        {
          label: 'Nombre de visites Distributeur réalisé',
          field: 'nbr_visites_distributeur',
          type: 'number',
          filterable: true,
        },
        {
          label: 'Nombre de tournées cloturées',
          field: 'nbr_tournes',
          type: 'number',
          filterable: true,
        },

        {
          label: 'Actions',
          field: 'action',
        },
      ],
      rows: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  created() {
    this.userData = localStorage.getItem('userData')
    this.getResponsables()
    this.getCommerciaux()
    this.getCanaux()
    this.getZones()
    setTimeout(() => {
      if (!this.rows.length) {
        this.getCommerciaux()
      }
    }, 5000)
  },
  methods: {
    exportExcel() {
    },
    exportCSV() {
      const header = this.columns.map(column => column.label)
      const data = this.rows.map(row => this.columns.map(column => row[column.field]))
      let csvData = `${header.join(',')}\n`
      csvData += data.map(row => row.join(',')).join('\n')
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', `${this.filename}.csv`)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    checkFormValidity() {
      this.commercial.canalState = !!this.commercial.canal
      this.commercial.zoneState = !!this.commercial.zone
      this.commercial.responsableState = !!this.commercial.responsable
      this.commercial.nomState = this.commercial.nom.length > 0
      this.commercial.prenomState = this.commercial.prenom.length > 0
      this.commercial.telephoneState = this.commercial.telephone.length > 0
      let checkAuth = true
      if (!this.commercial.uuid) {
        this.commercial.loginState = this.commercial.login.length > 0
        this.commercial.mot_de_passeState = this.commercial.mot_de_passe.length > 0
        checkAuth = this.commercial.loginState && this.commercial.mot_de_passeState
      }
      return this.commercial.nomState
      && this.commercial.prenomState
      && this.commercial.canalState
      && this.commercial.zoneState
      && this.commercial.responsableState
      && this.commercial.telephoneState
      && checkAuth
    },
    resetModal() {
      this.commercial.uuid = null
      this.commercial.nom = ''
      this.commercial.nomState = null
      this.commercial.canal = ''
      this.commercial.responsable = ''
      this.commercial.zone = ''
      this.commercial.canalState = null
      this.commercial.responsableState = null
      this.commercial.zoneState = null
      this.commercial.prenom = ''
      this.commercial.prenomState = null
      this.commercial.telephone = ''
      this.commercial.telephoneState = null
      this.commercial.email = ''
      this.commercial.login = ''
      this.commercial.mot_de_passe = ''
      this.commercial.loginState = null
      this.commercial.mot_de_passeState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      this.saveCommercial()
    },
    handleDelete(id) {
      // eslint-disable-next-line no-restricted-globals,no-alert
      if (confirm('Êtes-vous sûr de vouloir supprimer cet élément ?')) {
        this.$http.delete(`${API_URL}commercials/${id}/`).then(response => {
          console.log(response.data)
          this.getCommerciaux()
          showToast(this, 'danger', 'Suppression', 'Element supprimé')
        }).catch(error => {
          console.log(error)
        })
      } else {
        // Annuler la suppression ici
      }
    },
    saveCommercial() {
      if (!this.commercial.uuid && this.commercial.uuid == null) {
        this.$http.post(`${API_URL}commercials/`, this.commercial).then(response => {
          console.log(response.data)
          showToast(this, 'primary', 'Ajout', 'Element ajouté')
          this.getCommerciaux()
          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs['commercial-modal'].toggle('#toggle-btn')
          })
        }).catch(error => {
          console.log(error)
        })
      } else {
        this.$http.put(`${API_URL}commercials/${this.commercial.uuid}/`, this.commercial).then(response => {
          console.log(response.data)
          showToast(this, 'primary', 'Ajout', 'Element ajouté')
          this.getCommerciaux()
          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs['commercial-modal'].toggle('#toggle-btn')
          })
        }).catch(error => {
          console.log(error)
        })
      }
    },
    getResponsables() {
      this.$http.get(`${API_URL}responsables/`)
        .then(response => {
          this.responsables = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    getCommerciaux() {
      // eslint-disable-next-line no-empty
      if (this.responsableId) {
      } else {
        // console.log(localStorage.getItem('userData'))
        this.$http.get(`${API_URL}commercials/`)
          .then(response => {
            this.rows = response.data
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    getCanaux() {
      this.$http.get(`${API_URL}canals/`)
        .then(response => {
          this.canaux = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    getZones() {
      this.$http.get(`${API_URL}zones/`)
        .then(response => {
          this.zones = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    handleUpdate(item) {
      this.commercial.uuid = item.uuid
      this.commercial.nom = item.last_name
      this.commercial.prenom = item.first_name
      this.commercial.telephone = item.telephone
      this.commercial.email = item.email
      this.commercial.canal = item.canal_r
      this.commercial.zone = item.zone_r
      this.commercial.responsable = item.responsable_r

      this.$refs['commercial-modal'].toggle('#toggle-btn')
    },
  },
}
</script>

<style scoped>

</style>
